import { datadogRum } from '@datadog/browser-rum'

const initializeDatadog = () => {
  datadogRum.init({
    applicationId: window.env.DATADOG_RUM_APPLICATION_ID,
    clientToken: window.env.DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'avant-redesign',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: window.env.DATADOG_RUM_SAMPLE_RATE,
    trackInteractions: true,
    enabled: true,
    // env: activeEnv
    env: window.env.GATSBY_ACTIVE_ENV,
    /* eslint consistent-return: off */
    beforeSend: event => {
      if (
        event?.type === 'resource' &&
        event?.resource?.type === 'xhr' &&
        event?.resource?.url?.includes('/whoami')
      ) {
        return false
      }
    }
  })
  window.datadogRum = datadogRum
}

export default initializeDatadog
